import React, { useEffect, useState } from 'react';
import Donate from './donate';
const Countdown = () => {
  const [timeLeft, setTimeLeft] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });

  useEffect(() => {
    const countdownDate = new Date('October 23, 2025 00:00:00 GMT+3');

    const timer = setInterval(() => {
      const now = new Date().getTime();
      const difference = countdownDate - now;

      if (difference < 0) {
        clearInterval(timer);
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      } else {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);

        setTimeLeft({ days, hours, minutes, seconds });
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className="min-h-screen bg-black flex justify-center font-inter text-white items-center p-4">
      <Donate />
      <div className="items-center w-64 h-auto bg-red-400 p-2 rounded-md absolute text-center top-4 md:left-4 md:text-start">The website has been switched to countdown mode</div>
      <div className="max-w-lg w-full bg-gray-800 rounded-lg p-4 shadow-lg">
        <h1 className="font-bold text-2xl">See you in a next year :)</h1>
        <div className="grid grid-cols-2 gap-2 mt-4">
          <div className="flex flex-col items-center justify-center bg-gray-700 rounded-lg p-4">
            <p className="text-3xl text-cyan-300 font-bold">{timeLeft.days}</p>
            <p className="text-gray-400">Days</p>
          </div>
          <div className="flex flex-col items-center justify-center bg-gray-700 rounded-lg p-4">
            <p className="text-3xl font-bold">{timeLeft.hours}</p>
            <p className="text-gray-400">Hours</p>
          </div>
          <div className="flex flex-col items-center justify-center bg-gray-700 rounded-lg p-4">
            <p className="text-3xl font-bold">{timeLeft.minutes}</p>
            <p className="text-gray-400">Minutes</p>
          </div>
          <div className="flex flex-col items-center justify-center bg-gray-700 rounded-lg p-4">
            <p className="text-3xl font-bold">{timeLeft.seconds}</p>
            <p className="text-gray-400">Seconds</p>
          </div>
        </div>
      </div>
<div className="fixed bottom-0 h-12 w-full bg-gray-900 bg-opacity-90 flex items-center justify-center">
 <p className="font-bold">webring</p>
        <a
          href="https://otomir23.me/webring/17/prev"
          className="absolute left-4 hover:underline"
          target="_self"
        >
          ‹ prev
        </a>
        <a
          href="https://otomir23.me/webring/17/next"
          className="absolute right-4 hover:underline"
          target="_self"
        >
          next ›
        </a>
</div>
    </div>
  );
};

export default Countdown;