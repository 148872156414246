import { useState } from "react";
import qr from './qrton.png';
import * as SolarIconSet from "solar-icon-set";
export default function Donate() {
  const [isOpen, setIsOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const textToCopy = "UQB9pzGeT_5AlbgceIg3JwqWbPC9CoqlgsUalzeEUwm9la5W";

  const handleCopy = () => {
    const input = document.createElement("input");
    input.value = textToCopy;
    document.body.appendChild(input);
    input.select();
    try {
      document.execCommand("copy");
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error("Ошибка копирования", err);
    }
    document.body.removeChild(input);
  };

  return (
    <>
      <button
  onClick={() => setIsOpen(true)}
  className="fixed md:top-4 bottom-14 right-4 bg-blue-700 items-center text-white py-2 px-4 rounded-lg hover:from-blue-500 hover:to-blue-800 transition-colors z-50 h-12"
>
  Donate
</button>

      <div
        className={`fixed inset-0 bg-black bg-opacity-50 backdrop-blur-xl flex items-center justify-center z-50 transition-all duration-300 ${
          isOpen ? "opacity-100 visible" : "opacity-0 invisible"
        }`}
        onClick={() => setIsOpen(false)}
      >
        <div className="p-4 sm:p-8">
          <div
            onClick={(e) => e.stopPropagation()}
            className={`relative bg-blue-800 p-4 sm:p-6 rounded-2xl sm:mx-8 shadow-2xl transform transition-all duration-300 ${
              isOpen ? "scale-100 translate-y-0" : "scale-90 translate-y-20 opacity-0"
            }`}
          >
            <h1 className="text-white font-extrabold text-xl sm:text-2xl mb-2 text-center">
              Support Me
            </h1>
            <p className="text-white text-center mb-4 sm:mb-6">
              Plz help me after the army.
            </p>

            <div className="flex justify-center mb-4 sm:mb-6">
              <img
                src={qr}
                alt="QR Code"
                className="w-48 h-48 sm:w-48 sm:h-48 rounded-lg shadow-md object-cover"
              />
            </div>
            <div className="flex items-center bg-blue-950 text-white rounded-lg shadow-inner">
              <input
                type="text"
                readOnly
                value={textToCopy}
                className="bg-transparent flex-1 outline-none text-base sm:text-lg px-3 sm:px-4 w-full overflow-hidden"
              />
              <button
                onClick={handleCopy}
                className="px-3 sm:px-4 py-2 bg-blue-700 rounded-r-lg hover:bg-blue-600 transition-colors"
              >
                Copy
              </button>
            </div>

            {copied && (
              <div
                className={`fixed top-4 right-4 bg-green-500 text-white px-3 py-1 rounded-full text-sm shadow-md transform transition-transform duration-300 ${
                  copied ? "scale-100 opacity-100" : "scale-75 opacity-0"
                }`}
              >
                Copied!
              </div>
            )}

            <button
              onClick={() => setIsOpen(false)}
              className="mt-4 w-full bg-blue-950 text-white py-2 rounded-lg hover:bg-blue-900 transition-colors"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </>
  );
}